import type { ImageProps } from 'next/image'
import Image from 'next/image'

export const CustomImage: React.FC<ImageProps> = (props) => {
  const isUnoptimized =
    props.src && typeof props.src === 'string'
      ? props.src.includes('svg')
      : false

  return (
    <Image
      unoptimized={isUnoptimized}
      {...props}
    />
  )
}
