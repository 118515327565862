'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  useToast,
} from '@vendure/ui-components'
import { useForm } from 'react-hook-form'
import { CgSpinner } from 'react-icons/cg'
import { useIntl } from 'react-intl'
import { z } from 'zod'

export const formSchema = z.object({
  email: z.string().email(),
})

export function FooterNewsletterForm() {
  const intl = useIntl()
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })
  const { toast } = useToast()
  const mutation = useMutation({
    mutationFn: async (data: z.infer<typeof formSchema>) => {
      const res = await fetch('/api/newsletter', {
        method: 'POST',
        body: JSON.stringify(data),
      })
      return await res.json()
    },
    mutationKey: ['newsletter', 'subscribe'],
  })

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    mutation.mutate(values, {
      onSuccess() {
        // TODO: translations
        toast({
          variant: 'success',
          title: 'Successfully subscribed!',
          description:
            'From now on you will never miss out on the interesting stuff happening here at Vendure!',
        })
        form.reset()
      },
      onError() {
        toast({
          variant: 'destructive',
          title: 'Oops, something failed on our end.',
          description: 'Please try later or contact us directly.',
        })
      },
    })
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-row items-stretch gap-1"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => {
            return (
              <FormItem>
                <FormControl>
                  <Input
                    className="h-auto py-2"
                    {...field}
                    placeholder={intl.formatMessage({
                      id: 'footer.newsletter.input_placeholder',
                    })}
                  />
                </FormControl>
              </FormItem>
            )
          }}
        ></FormField>
        <Button variant="secondary">
          {mutation.isPending && <CgSpinner className="animate-spin" />}
          {!mutation.isPending && (
            <span>
              {intl.formatMessage({
                id: 'footer.newsletter.button_submit_label',
              })}
            </span>
          )}
        </Button>
      </form>
    </Form>
  )
}
