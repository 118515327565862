import type { ISbStoriesParams, ISbStoryData } from '@storyblok/react/rsc'
import { getStoryblokApi } from '@storyblok/react/rsc'
import type { ConfigStoryblok, PageStoryblok } from '@vendure/codegen/storyblok'
import type { IStoryResolverEvent } from '@vendure/storyblok-helpers'
import {
  EventBus,
  PRE_RESOLVE_EVENT,
  buildBreadcrumbStories,
  loadStoryWithAllResolvedRelations,
} from '@vendure/storyblok-helpers'
import type { ParsedUrlQuery } from 'querystring'
import type { StoryblokStory } from 'storyblok-generate-ts'
import { getCaseStudies } from './caseStudy'

export interface IGetServerSideProps extends IGetStaticProps {}

export interface IGetStaticProps {
  story: ISbStoryData | StoryblokStory<any> | null
  config: any
  breadcrumbs?: any
  preview?: boolean
  previewData?: any
  disableTagManager?: boolean
}

export interface IStaticParams extends ParsedUrlQuery {
  slug?: string[]
}

export const resolveRelations = [
  'primaryContactTeaser.contactPerson',
  'includeSnippet.snippets',
  'personCard.person',
  'caseStudyGrid.caseStudies',
  'caseStudy.customer',
  'caseStudyCarousel.items',
]

export const deepRelations = [
  'articlesTeaser.articles',
  'article.author',
  'article.category',
  'planFeatureItem.feature',
  'caseStudy.customer',
]

const populateArticles = async (
  language: string,
  blocks: any[] = [],
  insideStoryblok: any,
) => {
  const storyblokApi = getStoryblokApi()
  for (const blok of blocks) {
    if (blok.component === 'contentSection') {
      await populateArticles(language, blok.innerBlocks, insideStoryblok)
    }
    if (blok.component === 'blogArticles') {
      // we populate articles + on-demand webinars
      const categories = blok.categories
      const hasArticles = (blok.articles?.length ?? 0) > 0
      const hasCategories = (blok.categories?.length ?? 0) > 0

      if (!hasArticles) {
        const query = hasCategories
          ? {
              categories: {
                any_in_array: categories,
              },
            }
          : {}

        const { data: articles } = await storyblokApi.get('cdn/stories', {
          version: insideStoryblok ? 'draft' : 'published',
          sort_by: 'sort_by_date:desc',
          filter_query: {
            ...query,
            ...{
              component: {
                in: 'article',
              },
            },
          },
        })

        blok.articles = articles.stories
      }
    }
  }
}

const populateCaseStudies = async (
  language: string,
  blocks: any[] = [],
  insideStoryblok: any,
) => {
  for (const blok of blocks) {
    if (blok.component === 'contentSection') {
      await populateCaseStudies(language, blok.innerBlocks, insideStoryblok)
    }
    if (blok.component === 'caseStudyGrid') {
      if (blok.showAllAvailable) {
        const caseStudies = await getCaseStudies(
          language,
          [],
          [],
          insideStoryblok,
        )

        blok.caseStudies = caseStudies
      }
    }
  }
}

export async function loadConfig(sbParams: ISbStoriesParams) {
  const storyblokApi = getStoryblokApi()

  const { data: config } = await storyblokApi.get(
    'cdn/stories/config',
    sbParams,
    {
      next: {
        tags: ['page-config'],
      },
    },
  )

  return config.story as StoryblokStory<ConfigStoryblok> | undefined
}

export const loadPageDataBySlug = async <T>(
  slug: string | string[],
  insideStoryblok: boolean,
  language = 'en',
) => {
  slug = Array.isArray(slug) ? slug.join('/') : String(slug)
  const IS_DEV = process.env.NODE_ENV === 'development'
  const storyblokApi = getStoryblokApi()
  let data, config, breadcrumbs

  // load the draft version
  const sbParams: ISbStoriesParams = {
    language,
    version: insideStoryblok || IS_DEV ? 'draft' : 'published',
    resolve_relations: resolveRelations,
  }

  await EventBus.getInstance().register(
    PRE_RESOLVE_EVENT,
    async ({ slug, sbParams, data }: IStoryResolverEvent) => {
      if (data.story.content.component === 'page') {
        await populateCaseStudies(
          language,
          data.story.content.blocks,
          insideStoryblok,
        )
      }
    },
  )

  try {
    data = await loadStoryWithAllResolvedRelations<T>(
      slug,
      storyblokApi,
      sbParams,
      deepRelations,
    )
  } catch (e) {
    console.error('Could not load page data', slug, e)
  }

  try {
    config = await loadConfig(sbParams)
  } catch (e) {
    console.error('Could not config', e)
  }
  try {
    breadcrumbs = await buildBreadcrumbStories<PageStoryblok>(slug, language)
  } catch (e) {
    console.error('Could not load breadcrumbs', e)
  }

  return {
    data,
    config: config,
    breadcrumbs,
  }
}
