'use client'

import type { ArticleStoryblok } from '@vendure/codegen/storyblok'
import { loadArticles } from '@vendure/helpers'
import { Button } from '@vendure/ui-components'
import { parseAsInteger, useQueryState } from 'nuqs'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import type { StoryblokStory } from 'storyblok-generate-ts'
import { ArticleCard } from '../molecules'

interface IArticleGrid {
  articles: Array<StoryblokStory<ArticleStoryblok>>
  total: string | null
}

export const ArticleGrid: React.FC<IArticleGrid> = ({ articles, total }) => {
  const [loadedArticles, setLoadedArticles] = useState(articles)
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1))
  const intl = useIntl()

  const loadMoreArticles = async () => {
    const { articles: moreArticles } = await loadArticles('en', page)
    setLoadedArticles([...loadedArticles, ...moreArticles])
  }

  useEffect(() => {
    if (page !== 1) {
      loadMoreArticles()
    }
  }, [page])

  return (
    <div className="mx-auto max-w-screen-sm">
      <div className="flex flex-col gap-20">
        {loadedArticles.map((item) => {
          return (
            <ArticleCard
              key={item.uuid}
              article={item}
            />
          )
        })}
      </div>
      <div className="flex items-center justify-between py-10">
        <span>
          {loadedArticles.length} of {total}
        </span>
        {parseInt(total ?? '0') > loadedArticles.length && (
          <Button
            variant="secondary"
            onClick={() => {
              setPage(page + 1)
            }}
          >
            {intl.formatMessage({ id: 'pagination.actions.load_more' })}
          </Button>
        )}
      </div>
    </div>
  )
}
