'use client'

import type { PersonStoryblok } from '@vendure/codegen/storyblok'
import type React from 'react'
import type { StoryblokStory } from 'storyblok-generate-ts'
import { CustomImage } from './CustomImage'
import { GradientCard } from './GradientCard'

interface HorizontalPersonCardProps {
  person: StoryblokStory<PersonStoryblok>
}

export const HorizontalPersonCard: React.FC<HorizontalPersonCardProps> = ({
  person,
}) => {
  return (
    <div className="flex items-center gap-4">
      {person.content.image?.filename && (
        <GradientCard
          outerProps={{
            className: 'rounded-full w-14 h-14 overflow-hidden',
          }}
        >
          <CustomImage
            src={person.content.image.filename}
            alt={person.content.name ?? ''}
            style={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
            }}
            width="80"
            height="80"
          />
        </GradientCard>
      )}
      <div>
        <p className="text-md text-bold font-semibold text-white">
          {person.content.title && <span>{person.content.title} </span>}
          {person.content.name}
        </p>
        <p className="text-sm">{person.content.role}</p>
      </div>
    </div>
  )
}
