import moment from 'moment'

interface IFormattedDate {
  date?: string | null
  format?: string
}

export const FormattedDate: React.FC<IFormattedDate> = ({ date, format }) => {
  return <span>{moment(date).format(format ?? 'dddd, MMMM Do YYYY')}</span>
}
