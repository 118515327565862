'use client'

import type { ButtonStoryblok } from '@vendure/codegen/storyblok'
import { ArrowRightUpIcon } from '@vendure/shared-icons'
import { Button } from '@vendure/ui-components'
import { useMemo } from 'react'
import { MdArrowRight } from 'react-icons/md'
import { CustomLink } from './CustomLink'

export const ActionButton: React.FC<ButtonStoryblok> = ({
  text,
  icon,
  target,
  variant,
}) => {
  const IconComponent = useMemo(() => {
    switch (icon) {
      case 'arrow-right':
        return MdArrowRight
      case 'arrow-right-up':
        return ArrowRightUpIcon
      default:
    }
  }, [icon])

  const btnVariant = useMemo(() => {
    switch (variant) {
      case 'light':
        return 'secondary'
      case 'primary':
        return 'lead'
      case 'dark':
      default:
        return 'default'
    }
  }, [variant])

  return (
    <Button
      asChild
      variant={btnVariant}
      className="rounded-full"
    >
      <CustomLink href={target?.cached_url}>
        {text}
        {IconComponent && <IconComponent className="h-5 w-5" />}
      </CustomLink>
    </Button>
  )
}
