'use client'

export default function storyblokLoader({
  src,
  width,
  quality,
}: {
  src: string
  width: number
  quality: number
}) {
  const extension = src.split(/[#?&]/)[0].split('.').pop()?.trim()
  // only add the storyblok image service if image is not svg mainly for the logo in the navbar
  if (['png', 'jpeg', 'jpg', 'webp'].includes(extension ?? '')) {
    const filters = []

    if (quality) {
      filters.push(`quality:${quality}`)
    }

    if (width) {
      // max 2000px width
      width = width > 2000 ? 2000 : width

      const processedSrc = `${src}/m/${String(width)}x0/smart`

      if (filters.length > 0) {
        return `${processedSrc}/${filters.join(',')}`
      }

      return processedSrc
    }
    return `${src}/m/smart`
  }
  return src
}
