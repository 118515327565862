'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import type { FooterStoryblok } from '@vendure/codegen/storyblok'
import { getPrefixedLink } from '@vendure/storyblok-helpers'
import type React from 'react'
import { type PropsWithChildren } from 'react'
import {
  FaDiscord,
  FaGithub,
  FaLinkedin,
  FaNpm,
  FaTwitch,
  FaXTwitter,
  FaYoutube,
} from 'react-icons/fa6'
import { useIntl } from 'react-intl'
import { CustomImage, CustomLink, RichText } from '../atoms'
import { FooterNewsletterForm } from '../molecules'

interface FooterProps {
  blok: FooterStoryblok
}

const FooterMenuTitle: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <p className="text-md mb-3 font-semibold text-white lg:text-lg">
      {children}
    </p>
  )
}

export const Footer: React.FC<FooterProps> = ({ blok }) => {
  const intl = useIntl()

  const socials = [
    {
      title: 'LinkedIn',
      icon: FaLinkedin,
      link: blok.linkedin,
    },
    {
      title: 'GitHub',
      icon: FaGithub,
      link: blok.github,
    },
    {
      title: 'YouTube',
      icon: FaYoutube,
      link: blok.youtube,
    },
    {
      title: 'Twitter',
      icon: FaXTwitter,
      link: blok.twitter,
    },
    {
      title: 'NPM',
      icon: FaNpm,
      link: blok.npm,
    },
    {
      title: 'Discord',
      icon: FaDiscord,
      link: blok.discord,
    },
    {
      title: 'Twitch',
      icon: FaTwitch,
      link: blok.discord,
    },
  ]

  const navColumns = [
    {
      title: blok.footerNav1Title,
      links: blok.footerNav1Links,
    },
    {
      title: blok.footerNav2Title,
      links: blok.footerNav2Links,
    },
    {
      title: blok.footerNav3Title,
      links: blok.footerNav3Links,
    },
  ]

  return (
    <div {...storyblokEditable(blok)}>
      <div className="container">
        <div className="grid grid-cols-2 gap-3 py-20 md:grid-cols-5 md:gap-0">
          <div className="col-span-2 mb-4 flex-grow md:pr-8 lg:col-span-1 lg:mb-0 ">
            {blok.logo?.filename && (
              <CustomImage
                src={blok.logo.filename}
                alt={blok.logo.alt ?? ''}
                width={200}
                height={100}
                style={{ height: '30px', width: 'auto' }}
              />
            )}
            <RichText
              containerProps={{ className: 'mt-2 text-slate-600 text-xs' }}
              document={blok.text}
            />
          </div>
          {navColumns.map((item, i) => {
            return (
              <div
                key={`footer-nav-${i}`}
                className="mb-2 flex flex-col items-start lg:mb-0"
              >
                <FooterMenuTitle>{item.title}</FooterMenuTitle>
                <div className="align-start flex flex-col gap-2">
                  {item.links?.map((item) => {
                    return (
                      <CustomLink
                        key={item._uid}
                        href={getPrefixedLink(item.target)}
                        className="hover:text-primary-300 lg:text-md block text-sm text-slate-400 transition-colors"
                      >
                        {item.text}
                      </CustomLink>
                    )
                  })}
                </div>
              </div>
            )
          })}
          <div className="col-span-2 lg:col-span-1">
            <FooterMenuTitle>{blok.newsletterTitle}</FooterMenuTitle>
            <FooterNewsletterForm></FooterNewsletterForm>
            <RichText
              containerProps={{ className: 'text-sm text-slate-500 mt-2' }}
              document={blok.newsletterContent}
            />
          </div>
        </div>
        <div className="mt-5 flex flex-col items-center justify-between pb-20 pt-6 md:flex-row lg:pb-6">
          <p className="lg:text-md mb-2 mt-4 text-center text-sm text-slate-500 md:m-0 lg:mb-0 lg:text-left">
            {blok.copyright}
          </p>
          <div className="flex flex-row gap-5 text-3xl text-slate-500">
            {socials
              .filter((item) => item.link?.url)
              .map((item, i) => {
                const Icon = item.icon
                return (
                  <CustomLink
                    key={`socialLink${i}`}
                    href={item.link?.url}
                    target="_blank"
                    title={item.title}
                    className="hover:text-primary-400 text-slate-500 transition-colors"
                  >
                    <Icon className="h-6" />
                  </CustomLink>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
